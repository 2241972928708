import React from 'react';

export const Book = ({ title, children, cover, pdfLink }) => (
  <div className="book">
    <div className="row">
      <div className="col-md-5">
        <img src={cover} className="book-image" alt="book cover" />{' '}
      </div>
      <div className="col-md-7">
        <h3 className="book-title">{title}</h3>
        <p className="book-description">{children}</p>
        <ul className="book-download-list">
          <li className="book-download-item">
            <a href={pdfLink} download>
              <i className="fa fa-file-pdf-o"></i> Скачать PDF
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);
