import React from 'react'

const FaqArticle = ({ title, className = '', id, children }) => (
  <div className={`faq-article ${className}`} id={id}>
    <h4 className="faq-article-title">{title}</h4>
    <div className="faq-article-content">{children}</div>
  </div>
)

export default FaqArticle
