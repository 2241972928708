import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderBack from '../components/header-back';
import CategoryInfo from '../components/category-info';
import FaqArticle from '../components/faq-article';
import Layout from '../components/layout';
import { Book } from '../components/book';

import theChurchCover from '../assets/img/the-church-cover.jpg';

const Assembly = () => (
  <Layout>
    <Helmet>
      <title>О собрании во имя Господа Иисуса Христа</title>
      <meta
        name="description"
        content="Ибо где двое или трое собраны во имя Мое, там я посреди них. Матфея 18:20. О принципах собирания во имя Иисуса Христа"
      />
      <meta
        name="keywords"
        content="собрание, церковь, Господь Иисус, Матфея 18:20, двое или трое, я посреди них"
      />
    </Helmet>
    <HeaderBack small variant="simple">
      <h1 className="page-title">О собрании</h1>
      <p className="page-description">
        "Ибо где двое или трое собраны во имя Мое, там Я посреди них". (Ев.
        Матфея 18,20)
      </p>
    </HeaderBack>
    <div className="content">
      <div className="container">
        <CategoryInfo>
          <p>
            Действительно ли Его имя, Он сам, является божественным центром во
            всех собраниях христиан? Действительно ли все признают его как
            Господа во всех жизненных обстоятельствах? К сожалению, не всегда.
            Однако есть христиане, желающие собираться только во имя Иисуса
            Христа.
          </p>
        </CategoryInfo>
        <FaqArticle title="Что это за люди?">
          <p>
            Это люди, которые пришли к кресту со своими грехами и которые нашли
            прощение, мир и вечную жизнь в Иисусе Христе. Это люди, в которых
            Дух Бога посредством слова создал новую жизнь — жизнь от Бога. Они
            возрождены, они христиане, а не только называются ими.
          </p>
          <blockquote>
            <p>
              "Иисус сказал ему в ответ: истинно, истинно говорю тебе, если кто
              не родится свыше, не может увидеть Царствия Божия".
              <br />
              <cite>Ев. Иоанна 3,3</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Почему эти христиане не имеют особого названия?">
          <p>
            Потому что особые названия безоговорочно осуждаются в Слове Бога.
          </p>
          <blockquote>
            <p>
              "Потому что вы еще плотские. Ибо если между вами зависть, споры и
              разногласия, то не плотские ли вы? и не по человеческому ли обычаю
              поступаете? Ибо когда один говорит: «я Павлов», а другой: «я
              Аполлосов», то не плотские ли вы?".
              <br />
              <cite>1 Коринфянам 3,3.4</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "И ученики в Антиохии в первый раз стали называться Христианами".
              <br />
              <cite>Деяния Апостолов 11,26</cite>
            </p>
          </blockquote>
          <p>Поэтому они имеют право и желают называться только христианами.</p>
        </FaqArticle>
        <FaqArticle title="Лучше ли эти христиане других?">
          <p>
            Нет, разумеется, они не лучше других. Они могут только славить
            благодать Бога, которая их спасла, охраняет и восстанавливает
            духовно, если они согрешают. Они знают, что живут во плоти и что в
            этой старой неисправимой натуре случайно могут бесславить Господа
            своим беззаботным хождением.
          </p>
          <p>
            Они ценят и любят всех истинных детей Бога и знают, что связаны с
            ними воедино как члены тела Христа.
          </p>
        </FaqArticle>
        <FaqArticle title="Каково учение этих христиан?">
          <p>
            Единственным их учением является Слово Бога, Библия, а именно Ветхий
            и Новый Заветы. То есть они признают Библию, Ветхий и Новый Заветы,
            как целиком данное Богом Слово. Это Слово является единственным
            фундаментом как учения, так и всех их жизненных вопросов.
          </p>
          <blockquote>
            <p>
              "Все Писание богодухновенно и полезно для научения, для обличения,
              для исправления, для наставления в праведности".
              <br />
              <cite>2 Тимофею 3,16</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Чего хотят эти христиане?">
          <p>
            Они хотят идти в послушании тем путем, который указан в Слове Бога.
            Это послушание в первую очередь обуславливает отделение от всего,
            что называется мирским.
          </p>
          <blockquote>
            <p>
              "Не преклоняйтесь под чужое ярмо с неверными, ибо какое общение
              праведности с беззаконием? Что общего у света с тьмою?.. Или какое
              соучастие верного с неверным?".
              <br />
              <cite>2 Коринфянам 6,14.15</cite>
            </p>
          </blockquote>
          <p>
            Это послушание побуждает их выйти из соответствующих религиозных
            общин, церквей и объединений и занять то место, которое находит
            признание в Слове Бога.
          </p>
          <p>
            На основании Слова Бога существует только одна истинная церковь,
            собрание Бога, к которой относятся все возрожденные христиане.
          </p>
          <blockquote>
            <p>
              "И сами, как живые камни, устрояйте из себя дом духовный,
              священство святое, чтобы приносить духовные жертвы, благоприятные
              Богу Иисусом Христом".
              <br />
              <cite>1 Петра 2,5</cite>
            </p>
          </blockquote>
          <p>
            Бог ненавидит смешение света с тьмой. Верующие не могут поклоняться
            в святости Богу, находясь в общении с неверущими. Поэтому Бог
            требует от своих детей:
          </p>
          <blockquote>
            <p>
              "И потому выйдите из среды их и отделитесь... и не прикасайтесь к
              нечистому; и Я прииму вас. И буду вам Отцом, и вы будете Моими
              сынами и дщерями, говорит Господь Вседержитель".
              <br />
              <cite>2 Коринфянам 6,17.18</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Где же то место, которое признает Бог?">
          <p>О первых христианах сказано:</p>
          <blockquote>
            <p>
              "И они постоянно пребывали в учении апостолов, в общении и
              преломлении хлеба и в молитвах".
              <br />
              <cite>Деяния апостолов 2,42</cite>
            </p>
          </blockquote>
          <p>В других местах мы читаем:</p>
          <blockquote>
            <p>
              "Одно тело и один Дух... один Господь, одна вера, одно крещение,
              один Бог и Отец всех..."
              <br />
              <cite>Ефесянам 4,4-6</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Но Бог соразмерил тело... дабы не было разделения в теле".
              <br />
              <cite>1 Коринфянам 12,24.25</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Умоляю вас, братия, именем Господа нашего Иисуса Христа, чтобы
              все вы говорили одно и не было между вами разделений, но чтобы вы
              соединены были в одном духе и в одних мыслях".
              <br />
              <cite>1 Коринфянам 1,10</cite>
            </p>
          </blockquote>
          <p>
            Эти истины и наставления побуждали и побуждают верующих скорбеть о
            разобщенности в христианстве, отказаться от отдельных убеждений,
            которые не находят основания и подтверждения в Писании, и
            возвратиться к тому
          </p>
          <blockquote>
            <p>
              "... что вы слышали от начала".
              <br />
              <cite>1 Иоанна 2,24</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Ибо никто не может положить другого основания, кроме положенного,
              которое есть Иисус Христос".
              <br />
              <cite>1 Коринфянам 3,11</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Что же было в начале?">
          <p>Тогда верующие собирались во имя Иисуса, согласно Его Слову:</p>
          <blockquote>
            <p>
              "Где двое или трое собраны во имя Мое, там Я посреди них".
              <br />
              <cite>Ев. Матфея 18,20</cite>
            </p>
          </blockquote>
          <p>
            Однако на протяжении столетий многое изменилось и стало
            противоречить Библии. Христиане, которые берут сейчас в качестве
            основания вышеуказанное обещание Господа, отказываются от
            человеческих церемоний, порядков и устройств и желают подчиняться
            влиянию и водительству Духа Бога, а не человеку.
          </p>
          <blockquote>
            <p>
              "Дары различны, но Дух один и тот же; и служения различны, а
              Господь один и тот же; и действия различны, а Бог один и тот же,
              производящий все во всех".
              <br />
              <cite>1 Коринфянам 12,4-6</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Все же сие производит один и тот же Дух, разделяя каждому особо,
              как Ему угодно".
              <br />
              <cite>1 Коринфянам 12,11</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Не возникает ли в такой обстановке беспорядок?">
          <p>
            Когда верующие ожидают своего Господа, когда они сознают свою немощь
            и подчиняются руководству Святого Духа, тогда они на практике
            убеждаются, насколько Бог верен своим обещаниям.
          </p>
          <blockquote>
            <p>
              "Потому что Бог не есть Бог неустройства, но мира. Так бывает во
              всех церквах у святых".
              <br />
              <cite>1 Коринфянам 14,33</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <FaqArticle title="Не создают ли христиане, оставляя религиозную систему, новую партию?">
          <p>
            Возвращаясь к тому, что было "от начала", эти христиане не создают
            новую партию. Они желают подчиняться только Слову Бога и выражать в
            этом смысле единство всех детей Бога, что означает
          </p>
          <blockquote>
            <p>
              "... сохранять единство Духа в союзе мира".
              <br />
              <cite>Ефесянам 4,3</cite>
            </p>
          </blockquote>
          <p>
            Это единство можно восстановить практически лишь на основе
            послушания и отделения от всех созданных человеком религиозных
            организаций.
          </p>
          <blockquote>
            <p>
              "Итак выйдем к Нему за стан, нося Его поругание".
              <br />
              <cite>Евреям 13,13</cite>
            </p>
          </blockquote>
          <p>
            Если ты готов идти путем послушания, согласно Слову Бога, тогда
            действуй так, как поступали христиане в Верии.
          </p>
          <blockquote>
            <p>
              "Они приняли слово со всем усердием, ежедневно разбирая Писания,
              точно ли это так".
              <br />
              <cite>Деяния апостолов 17,11</cite>
            </p>
          </blockquote>
          <p>
            Молитвенно реши для себя важный вопрос, на каком месте Бог хочет
            видеть тебя в этом мире, и Бог откроет тебе свою волю. Но из этого
            следует вывод: нужно быть искренним и послушным Его Слову.
          </p>
        </FaqArticle>
        <FaqArticle title="Каким образом можно достичь членства?">
          <p>
            Священное Писание не говорит о каком-либо членстве. Оно признает
            только одно: все истинные дети Бога являются членами одного тела.
          </p>
          <blockquote>
            <p>
              "Мы члены друг другу".
              <br />
              <cite>Ефесянам 4,25</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Мы члены тела Его, от плоти Его и от костей Его".
              <br />
              <cite>Ефесянам 5,30</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Ибо как в одном теле у нас много членов, но не у всех членов одно
              и то же дело, так мы, многие, составляем одно тело во Христе, а
              порознь один для другого члены".
              <br />
              <cite>Римлянам 12,4.5</cite>
            </p>
          </blockquote>
          <blockquote>
            <p>
              "Ибо, как тело одно, но имеет многие члены, и все члены одного
              тела, хотя их и много, составляют одно тело, — так и Христос. Ибо
              все мы одним Духом крестились в одно тело, Иудеи или Еллины, рабы
              или свободные, и все напоены одним Духом... Но Бог расположил
              члены, каждый в составе тела, как ему было угодно... Посему,
              страдает ли один член — страдают с ним все члены; славится ли один
              член — с ним радуются все члены. И вы — тело Христово, а порознь —
              члены".
              <br />
              <cite>1 Коринфянам 12,12-14.18.26.27</cite>
            </p>
          </blockquote>
        </FaqArticle>
        <hr style={{ marginTop: 40, marginBottom: 40, borderTopWidth: 4 }} />
        <div className="the-church-preview">
          <Book
            cover={theChurchCover}
            title="Церковь. Куда мне ходить?"
            pdfLink="/books/TheChurch.pdf">
            Глядя на современное христианство, вы, возможно, спрашиваете себя:
            <ul>
              <li>Почему сегодня так много разных христианских групп?</li>
              <li>Как найти тех, кто собирается согласно Слову Бога?</li>
              <li>
                Каким образом осуществить единение церкви вокруг Господа Иисуса?
              </li>
            </ul>
            Цель данной брошюры и заключается в том, чтобы исследовать происхож-
            дение нынешнего разделения в христианстве и показать, что существует
            возможность единения церкви вокруг Господа Иисуса в соответствии с
            мыс- лями Бога. Это не связано с какой-либо христианской
            организацией, но под- разумевает то, чтобы просто обратиться к Слову
            Бога.
          </Book>
          <iframe
            title="Церковь. Куда мне ходить?"
            src="/books/TheChurch.pdf#toolbar=0"
            frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </Layout>
);

export default Assembly;
